import { createContext } from "react";

// create apollo
import { ApolloProvider } from "@apollo/client";
import client from "./index";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  return (
    <AuthContext.Provider
      value={{
        client: client,
      }}
    >
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </AuthContext.Provider>
  );
};
