import { gql } from "@apollo/client";

export const QUERIES = {
  uStellaLandlordSignUp: gql`
    mutation uStellaLandlordSignUp($payload: UstellaLandlordSignUpPayload!) {
      result: uStellaLandlordSignUp(payload: $payload) {
        firstName
        lastName
        address
        phone
        email
        jwtToken
      }
    }
  `,
  uStellaLandlordSignIn: gql`
    mutation uStellaLandlordSignIn($firebaseToken: String!) {
      result: uStellaLandlordSignIn(firebaseToken: $firebaseToken) {
        firstName
        lastName
        address
        phone
        email
        jwtToken
      }
    }
  `,
  uStellaVerifyLandlordToken: gql`
    query uStellaVerifyLandlordToken($jwtToken: String!) {
      result: uStellaVerifyLandlordToken(jwtToken: $jwtToken) {
        firstName
        lastName
        address
        phone
        email
        jwtToken
      }
    }
  `,
  uStellaSetLandlordProfile: gql`
    mutation uStellaSetLandlordProfile(
      $payload: UstellaLandlordProfilePayload!
    ) {
      result: uStellaSetLandlordProfile(payload: $payload)
    }
  `,
  uStellaLandlordBuildings: gql`
    query uStellaLandlordBuildings($buildingID: ID) {
      result: uStellaLandlordBuildings(buildingID: $buildingID) {
        building {
          buildingID
          internalName
          listingName
          location {
            address
            latitude
            longitude
            neighbourhood
            city
            state
            country
            zipCode
          }
          imageURL
          propertyCount
          floorCount
        }
        occupancyPercentage
        revenue {
          allTimeTotal
          thisYearTotal
          thisMonthTotal
        }
        expenses {
          allTimeTotal
          thisYearTotal
          thisMonthTotal
        }
        shortTerm {
          propertyCount
          occupancyPercentage
          occupancyCount
          expenses {
            allTimeTotal
            thisYearTotal
            thisMonthTotal
          }
          revenue {
            allTimeTotal
            thisYearTotal
            thisMonthTotal
          }
          propertyTypeStats {
            bedrooms
            propertyCount
            occupancyPercentage
          }
          propertyMaintenanceStats {
            bedrooms
            totalCases
            pendingCases
            cancelledCases
            completedCases
          }
        }
        longTerm {
          propertyCount
          occupancyPercentage
          occupancyCount
          expenses {
            allTimeTotal
            thisYearTotal
            thisMonthTotal
          }
          revenue {
            allTimeTotal
            thisYearTotal
            thisMonthTotal
          }
          propertyTypeStats {
            bedrooms
            propertyCount
            occupancyPercentage
          }
          propertyMaintenanceStats {
            bedrooms
            totalCases
            pendingCases
            cancelledCases
            completedCases
          }
        }
        commercial {
          propertyCount
          occupancyPercentage
          occupancyCount
          expenses {
            allTimeTotal
            thisYearTotal
            thisMonthTotal
          }
          revenue {
            allTimeTotal
            thisYearTotal
            thisMonthTotal
          }
          propertyTypeStats {
            bedrooms
            propertyCount
            occupancyPercentage
          }
          propertyMaintenanceStats {
            bedrooms
            totalCases
            pendingCases
            cancelledCases
            completedCases
          }
        }
        propertyTypeStats {
          bedrooms
          propertyCount
          occupancyPercentage
        }
        propertyMaintenanceStats {
          bedrooms
          totalCases
          pendingCases
          cancelledCases
          completedCases
        }
      }
    }
  `,
  uStellaLandlordMaintenanceStats: gql`
    query uStellaLandlordMaintenanceStats(
      $filter: UstellaLandlordMaintenanceFilterInput!
    ) {
      result: uStellaLandlordMaintenanceStats(filter: $filter) {
        totalCases
        pendingCases
        cancelledCases
        completedCases
      }
    }
  `,
  uStellaLandlordMaintenanceDepartments: gql`
    query uStellaLandlordMaintenanceDepartments {
      result: uStellaLandlordMaintenanceDepartments {
        departmentID
        name
      }
    }
  `,
  uStellaLandlordMaintenanceCases: gql`
    query uStellaLandlordMaintenanceCases(
      $filter: UstellaLandlordMaintenanceFilterInput!
    ) {
      result: uStellaLandlordMaintenanceCases(filter: $filter) {
        caseID
        title
        building {
          buildingID
          internalName
          listingName
        }
        property {
          propertyID
          internalName
          listingName

          propertyType
          createdAt
          lastUpdated
          propertyFloor
          propertyNumber
          bedrooms
          beds
          baths
          spaceSqm
        }
        maintenanceDepartmentName
        status
        totalExpenses
        reportedAt
        resolvedAt
        description
        closureNotes
      }
    }
  `,
  uStellaLandlordMaintenanceHistory: gql`
    query uStellaLandlordMaintenanceHistory($caseID: ID!) {
      result: uStellaLandlordMaintenanceHistory(caseID: $caseID) {
        action
        createdAt
      }
    }
  `,
  uStellaLandlordProperties: gql`
    query uStellaLandlordProperties(
      $filter: UstellaLandlordPropertyFilterInput!
    ) {
      result: uStellaLandlordProperties(filter: $filter) {
        property {
          propertyID
          internalName
          listingName
          # imageURL
          propertyType
          createdAt
          lastUpdated
          propertyFloor
          propertyNumber
          bedrooms
          beds
          baths
          spaceSqm
        }
        isOccupied
        isBlocked
        occupancyPercentage
        revenue {
          allTimeTotal
          thisYearTotal
          thisMonthTotal
        }
        expenses {
          allTimeTotal
          thisYearTotal
          thisMonthTotal
        }
        shortTermOccupancy {
          reservationStatus
          channel
          checkIn
          checkOut
          nights
          numberOfGuests
          guestFirstName
          guestLastName
          guestPhone
          guestEmail
          reservationTotal
          reservationCurrency
          createdAt
        }
        longTermOccupancy {
          isLeaseActive
          leaseStartDate
          leaseEndDate
          tenantFirstName
          tenantLastName
          tenantPhone
          tenantEmail
          tenantDocuments {
            docType
            docURLs {
              url
              label
            }
          }
          leaseDocuments {
            docType
            docURLs {
              url
              label
            }
          }
          leaseAmount
          leaseCurrency
          leaseTerm
          # leaseFrequency
          leaseMethod
          createdAt
        }
        commercialOccupancy {
          isLeaseActive
          leaseStartDate
          leaseEndDate
          companyName
          contactFirstName
          contactLastName
          contactPhone
          contactEmail
          companyDocuments {
            docType
            docURLs {
              url
              label
            }
          }
          leaseDocuments {
            docType
            docURLs {
              url
              label
            }
          }
          leaseAmount
          leaseCurrency
          leaseTerm
          # leaseFrequency
          leaseMethod
          createdAt
        }
      }
    }
  `,
  uStellaLandlordExpenseCategories: gql`
    query uStellaLandlordExpenseCategories {
      result: uStellaLandlordExpenseCategories {
        parentCategoryID
        parentCategoryName
        subCategoryID
        subCategoryName
        expensesCount
      }
    }
  `,
  uStellaLandlordExpenses: gql`
    query uStellaLandlordExpenses($filter: UstellaLandlordExpenseFilterInput!) {
      result: uStellaLandlordExpenses(filter: $filter) {
        category
        invoiceDate
        invoiceNumber
        amount
        vat
        paymentMethod
        receiptURL
        description
        createdAt
      }
    }
  `,
  uStellaLandlordShortTermOccupancies: gql`
    query uStellaLandlordShortTermOccupancies($propertyID: ID!) {
      result: uStellaLandlordShortTermOccupancies(propertyID: $propertyID) {
        reservationStatus
        channel
        checkIn
        checkOut
        nights
        numberOfGuests
        guestFirstName
        guestLastName
        guestPhone
        guestEmail
        reservationTotal
        reservationCurrency
        createdAt
      }
    }
  `,
  uStellaLandlordLongTermOccupancies: gql`
    query uStellaLandlordLongTermOccupancies($propertyID: ID!) {
      result: uStellaLandlordLongTermOccupancies(propertyID: $propertyID) {
        isLeaseActive
        leaseStartDate
        leaseEndDate
        tenantFirstName
        tenantLastName
        tenantPhone
        tenantEmail
        tenantDocuments {
          docType
          docURLs {
            url
            label
          }
        }
        leaseDocuments {
          docType
          docURLs {
            url
            label
          }
        }
        securityDeposit {
          amount
          paymentMethod
        }
        leaseAmount
        leaseCurrency
        leaseTerm
        numberOfInstallments
        leaseMethod
        createdAt
      }
    }
  `,
  uStellaLandlordCommercialOccupancies: gql`
    query uStellaLandlordCommercialOccupancies($propertyID: ID!) {
      result: uStellaLandlordCommercialOccupancies(propertyID: $propertyID) {
        isLeaseActive
        leaseStartDate
        leaseEndDate
        companyName
        contactFirstName
        contactLastName
        contactPhone
        contactEmail
        companyDocuments {
          docType
          docURLs {
            url
            label
          }
        }
        leaseDocuments {
          docType
          docURLs {
            url
            label
          }
        }
        securityDeposit {
          amount
          paymentMethod
        }
        leaseAmount
        leaseCurrency
        leaseTerm
        numberOfInstallments
        leaseMethod
        createdAt
      }
    }
  `,
  uStellaLandlordStats: gql`
    query uStellaLandlordStats($filter: UstellaLandlordStatsFilterInput!) {
      result: uStellaLandlordStats(filter: $filter) {
        shortTerm {
          name
          occupancyPercentage
          revenue
          expenses
        }
        longTerm {
          name
          occupancyPercentage
          revenue
          expenses
        }
        commercial {
          name
          occupancyPercentage
          revenue
          expenses
        }
      }
    }
  `,
};
