import firebase from "firebase/app";

const signOut = () => {
  return new Promise(async (resolve, reject) => {
    await firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.removeItem("token");
        resolve();
      })
      .catch((err) => {
        console.log(err);
        reject(`Couldn't signed out. Please refresh the page and try again.`);
      });
  });
};

export default signOut;
