export const ROUTES = {
  AUTH: {
    LOGIN: "/login",
    SIGNUP: "/signup",
    FORGOT_PASSWORD: "/forgot-password",
  },

  DASHBOARD: "/dashboard",
  MAINTENANCE: "/maintenance",
  MAINTENANCE_CASE: "/maintenance/case/:id",
  PROPERTIES: {
    BASE: "/properties",
    OVERVIEW: "/overview",
    SHORT_TERM: "/short-term",
    LONG_TERM: "/long-term",
    COMMERCIAL: "/commercial",
    UNIT: "/unit",
    EXPENSES: "/expenses",
    OCCUPANCIES: "/occupancies",
  },
  PROFILE: "/profile",
  LOGOUT: "/logout",
};
