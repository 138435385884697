const checkPasswords = (data, e) => {
  const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,50}$/;

  const password = data.password;
  const passwordConfirm = data.confirm;

  if (passwordPattern.test(password) === false) {
    return {
      error: {
        name: "password",
        message: `Please use 8 or more characters with a mix of letters, numbers & symbols in your password.`,
      },
    };
  }

  if (passwordConfirm !== password) {
    return {
      error: {
        name: "confirm",
        message: `Those passwords didn’t match. Try again.`,
      },
    };
  }

  return false;
};

export default checkPasswords;
