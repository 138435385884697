import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";

// Constant and assets
import UStellaLogo from "../../../assets/images/logo/logo.svg";
import { ROUTES } from "../../../constants";
import { QUERIES } from "../../../lib/apollo/queries";

// Components
import ShowPassword from "./shared/ShowPassword";
import Loading from "./shared/Loading";
import ErrorBadge from "./shared/ErrorBadge";

// Auth functions
import getJwtToken from "../../shared/functions/auth/getJwtToken";
import getFirebaseToken from "../../shared/functions/auth/getFirebaseToken";

const Login = ({ user }) => {
  const location = useLocation();
  const history = useHistory();
  const resetPassword = location?.state?.resetPassword || false;

  const [data, setData] = useState({
    email: location?.state?.email || "",
    password: "",
  });

  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const [loginUser] = useMutation(QUERIES.uStellaLandlordSignIn);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (submitting) return;
    setSubmitting(true);
    setError(null);

    // Check if data is filled
    if (!data.email || !data.password) {
      setError(`Email or password is missing.`);
      return setSubmitting(false);
    }

    // Get Firebase Token
    const firebaseToken = await getFirebaseToken(data)
      .then((token) => token)
      .catch((err) => setError(err));

    if (!firebaseToken) {
      return setSubmitting(false);
    }

    // Get JWT Token from server
    const jwtToken = await getJwtToken(loginUser, firebaseToken)
      .then((token) => token)
      .catch(() => setError(`Something went wrong. Please try again later.`));
    if (!jwtToken) {
      return setSubmitting(false);
    }

    // Set cookie and return
    localStorage.setItem("token", jwtToken);
    setSubmitting(false);
    return history.push(ROUTES.DASHBOARD);
  };

  return (
    <div className={`container auth ${submitting ? "submitting" : ""}`}>
      <div className="row title">
        <div className="col">
          <img src={UStellaLogo} alt="UStella" className="logo" />
          <h4 className="header">Login to your account</h4>
        </div>
      </div>

      {/* Already logged in */}
      {user && (
        <div className="row continue-as">
          <div className="col">
            <Link
              to={ROUTES.DASHBOARD}
              className="button outline with-icon continue-link"
            >
              Continue as {`${user.firstName} ${user.lastName}`}
              <i className="bi-arrow-right icon" />
            </Link>
          </div>
        </div>
      )}
      <div className="divider mt-4" />

      <div className="row loader pb-3">
        <div className="col">
          <Loading isLoading={submitting} />
        </div>
      </div>

      {resetPassword && !error && (
        <div className="row">
          <div className="col">
            <p>
              Please check your inbox to reset your password. Then you can sign
              in with your new password.
            </p>
          </div>
        </div>
      )}

      {error && (
        <div className="row error">
          <div className="col">
            <ErrorBadge error={error} />
          </div>
        </div>
      )}

      <div className="row form">
        <form onSubmit={onSubmit} className="col form-col">
          {/* Email  */}
          <div className="row input_row">
            <div className="col input_group">
              <label htmlFor="email">Email address</label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Email address"
                onChange={(e) =>
                  setData({ email: e.target.value, password: data.password })
                }
                value={data.email}
              />
            </div>
          </div>

          {/* Password */}
          <div className="row input_row">
            <div className="col input_group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                onChange={(e) =>
                  setData({ email: data.email, password: e.target.value })
                }
                value={data.password}
                className={`input-password`}
              />
            </div>
          </div>

          {/* Password */}
          <div className="row password-utils my-4">
            <div className="col-6 show">
              <ShowPassword />
            </div>
            <div className="col-6 forgot">
              <Link
                to={{
                  pathname: ROUTES.AUTH.FORGOT_PASSWORD,
                  state: { email: data.email },
                }}
              >
                Forgot password?
              </Link>
            </div>
          </div>

          {/* Submit */}
          <div className="row">
            <div className="col">
              <button
                className="button auth submit"
                type="submit"
                disabled={submitting}
              >
                Login
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
