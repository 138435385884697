import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { QUERIES } from "./../../lib/apollo/queries";

import AppLoader from "./Loader/App";

const PrivateRoute = ({ component: RouteComponent, refetchUser, ...rest }) => {
  const token = localStorage.getItem("token");
  const user = useQuery(QUERIES.uStellaVerifyLandlordToken, {
    variables: { jwtToken: token },
    skip: token === null,
  });

  if (user.loading) {
    return <AppLoader />;
  }
  if(user.error)
  {
      return <Redirect to={`/login`} />;
  }

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        user.data?.result ? (
          <RouteComponent
            {...routeProps}
            user={user}
            refetchUser={refetchUser}
          />
        ) : (
          <Redirect to={`/login`} />
        )
      }
    />
  );
};

export default PrivateRoute;
