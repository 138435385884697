import React, { lazy, Suspense, useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

// Constants
import { ROUTES } from "./constants";

// Firebase
import firebase from "firebase/app";

// Apollo
import { useQuery } from "@apollo/client";
import { isAuthenticated } from "./components/shared/functions/auth/isAuthenticated";
import { QUERIES } from "./lib/apollo/queries";

// Layout Components
import Navbar from "./components/layouts/Navbar";
import Sidebar from "./components/layouts/Sidebar";

// Pages
import Login from "./components/pages/Auth/Login";
import Signup from "./components/pages/Auth/Signup";
import ForgotPassword from "./components/pages/Auth/ForgotPassword";
import Profile from "./components/pages/Auth/Profile";
import NotFound from "./components/pages/NotFound";

// Utils
import PrivateRoute from "./components/utils/PrivateRoute";
import ScrollToTop from "./components/utils/ScrollToTop";

// Breadcrumb context
import { BreadcrumbProvider } from "./contexts/BreadcrumbContext";

// Lazy Pages
const Maintenance = lazy(() => import("./components/pages/Maintenance"));
const MaintenanceCase = lazy(() =>
  import("./components/pages/Maintenance/Case")
);
const Dashboard = lazy(() => import("./components/pages/Dashboard"));
const Properties = lazy(() => import("./components/pages/Properties"));
const Unit = lazy(() => import("./components/pages/Unit"));

const Main = () => {
  const location = useLocation();
  const token = localStorage.getItem("token");

  const queryOptions = {
    variables: { jwtToken: token },
    skip: token === null,
  };
  const fetchUser = useQuery(QUERIES.uStellaVerifyLandlordToken, queryOptions);

  // Is auth page
  const authPages = Object.values(ROUTES.AUTH).map((page) => page);
  const [isAuthPage, setIsAuthPage] = useState(false);
  useEffect(() => {
    if (authPages.includes(location.pathname)) {
      setIsAuthPage(true);
      return;
    }
    setIsAuthPage(false);
  }, [location, authPages]);

  // Is user logged in
  const [user, setUser] = useState({
    state: false,
    data: null,
  });

  useEffect(() => {
    if (!isAuthenticated()) return;
    if (fetchUser.loading) return;

    if(fetchUser.error)
    {
        setUser({ state: false, data: null });
        return;
    }

    const userData = fetchUser.data?.result;
    if (fetchUser) {
      setUser({ state: true, data: userData });
      return;
    }

    setUser({ state: false, data: null });
  }, [fetchUser, isAuthPage]);

  // Log out immidiately when auth state changes
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        setUser(false);
      }
    });
  }, []);

  return (
    <ScrollToTop>
      <BreadcrumbProvider>
        <div className={`wrapper ${isAuthPage ? "auth" : "dashboard"}`}>
          {!isAuthPage && user.state === true && (
            <>
              <Sidebar user={user.data} />
              <Navbar user={user.data} />
            </>
          )}

          <Suspense fallback={<></>}>
            <Switch>
              {/* Public / Auth */}
              <Route
                exact
                path={ROUTES.AUTH.LOGIN}
                render={() => <Login user={user.data} />}
              />
              <Route
                exact
                path={ROUTES.AUTH.FORGOT_PASSWORD}
                component={ForgotPassword}
              />
              <Route exact path={ROUTES.AUTH.SIGNUP} component={Signup} />

              {/* Private */}
              {/* Profile */}
              <PrivateRoute
                exact
                path={ROUTES.PROFILE}
                component={Profile}
                refetchUser={fetchUser.refetch}
              />

              {/* Dashboard */}
              <PrivateRoute
                exact
                path={ROUTES.DASHBOARD}
                component={Dashboard}
              />

              {/* Unit details */}
              <PrivateRoute
                path={`${ROUTES.PROPERTIES.BASE}/:id${ROUTES.PROPERTIES.UNIT}/:propertyID`}
                component={Unit}
              />

              {/* Properties */}
              <PrivateRoute
                path={`${ROUTES.PROPERTIES.BASE}/:id`}
                component={Properties}
              />

              {/* Maintenance */}
              <PrivateRoute
                exact
                path={ROUTES.MAINTENANCE}
                component={Maintenance}
              />
              <PrivateRoute
                exact
                path={ROUTES.MAINTENANCE_CASE}
                component={MaintenanceCase}
              />

              <Route component={NotFound} />
            </Switch>
          </Suspense>
        </div>
      </BreadcrumbProvider>
    </ScrollToTop>
  );
};

export default Main;
