import { useState } from "react";
import Checkbox from "./Checkbox";

const ShowPassword = () => {
  const [isShown, setShown] = useState(false);

  const togglePassword = () => {
    setShown(!isShown);
    const passwords = document.getElementsByClassName("input-password");

    for (let password of passwords) {
      const type = password.type;

      if (type === "password") {
        password.type = "text";
      } else {
        password.type = "password";
      }
    }
  };

  return (
    <div className="show-password">
      <Checkbox
        onClick={togglePassword}
        value={isShown}
        name="toggle-password"
        label="Show password"
      />
    </div>
  );
};

export default ShowPassword;
