import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Main from "./Main";

// Init
import "./lib/firebase";
import { AuthProvider } from "./lib/apollo/context";
import { FirebaseProvider } from "./lib/firebase/provider";

// Style
import "./assets/sass/custom.scss";

import dayjs from "dayjs";
const advancedFormat = require("dayjs/plugin/advancedFormat");
const customParseFormat = require("dayjs/plugin/customParseFormat");
const isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
const isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
const isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

const UStellaLandlordApp = () => {
  return (
    <AuthProvider>
      <FirebaseProvider>
        <Router>
          <Main />
        </Router>
      </FirebaseProvider>
    </AuthProvider>
  );
};
export default UStellaLandlordApp;
