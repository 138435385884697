const getJwtToken = (loginUser, token) => {
  return new Promise(async (resolve, reject) => {
    await loginUser({
      variables: { firebaseToken: token },
    })
      .then((res) => {
        const jwtToken = res.data.result.jwtToken;
        resolve(jwtToken);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export default getJwtToken;
