import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { ROUTES } from "../../../constants";
import { QUERIES } from "../../../lib/apollo/queries";
import UStellaLogo from "./../../../assets/images/logo/logo.svg";

import checkPasswords from "../../shared/functions/auth/checkPasswords";
import ErrorBadge from "./shared/ErrorBadge";
import Loading from "./shared/Loading";
import ShowPassword from "./shared/ShowPassword";
import useQuery from "./shared/useQuery";

const Signup = () => {
  const location = useLocation();
  const history = useHistory();
  const query = useQuery();

  const [data, setData] = useState({
    email: query.get("email") || location?.state?.email || "",
    password: "",
    confirm: "",
  });

  useEffect(() => {
    if (!query.get("email")) {
      return history.push("/");
    }
  }, [query, history]);

  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [registerNewUser] = useMutation(QUERIES.uStellaLandlordSignUp);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (submitting) return;
    setSubmitting(true);
    setError(null);

    // Check if data is filled
    if (!data.password || !data.confirm) {
      setError({ message: `Please fill out the form.` });
      return setSubmitting(false);
    }

    // Check passwords
    const passwords = checkPasswords(data, e);
    if (passwords) {
      setError(passwords.error);
      return setSubmitting(false);
    }

    const payload = {
      email: data.email,
      password: data.password,
    };

    await registerNewUser({
      variables: {
        payload: payload,
      },
    })
      .then((res) => {
        const jwtToken = res.data.result.jwtToken;
        localStorage.setItem("token", jwtToken);
        history.push("/");
      })
      .catch(() =>
        setError({ message: `Something went wrong. Please try again later.` })
      );

    return setSubmitting(false);
  };

  return (
    <div className={`container auth ${submitting ? "submitting" : ""}`}>
      <div className="row title">
        <div className="col">
          <img src={UStellaLogo} alt="UStella" className="logo" />
          <h4 className="header">Create your account</h4>
        </div>
      </div>

      <div className="divider mt-4" />

      <div className="row loader pb-3">
        <div className="col">
          <Loading isLoading={submitting} />
        </div>
      </div>

      {error && (
        <div className="row error">
          <div className="col">
            <ErrorBadge error={error.message} />
          </div>
        </div>
      )}

      <div className="row form">
        <form onSubmit={onSubmit} className="col form-col">
          {/* Email  */}
          <div className="row input_row">
            <div className="col input_group disabled">
              <label htmlFor="email">Email address</label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Email address"
                disabled={true}
                defaultValue={data.email}
                className={`${error?.name?.email ? "error" : ""}`}
              />
            </div>
          </div>

          {/* Password */}
          <div className="row input_row">
            <div className="col-12 col-sm-6 input_group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                onChange={(e) =>
                  setData({
                    email: data.email,
                    password: e.target.value,
                    confirm: data.confirm,
                  })
                }
                value={data.password}
                autoFocus={true}
                className={`input-password ${
                  error?.name?.password ? "error" : ""
                }`}
              />
            </div>
            <div className="col-12 col-sm-6 input_group">
              <label htmlFor="password">Confirm</label>
              <input
                type="password"
                name="confirm"
                id="confirm"
                placeholder="Confirm"
                onChange={(e) =>
                  setData({
                    email: data.email,
                    password: data.password,
                    confirm: e.target.value,
                  })
                }
                value={data.confirm}
                className={`input-password ${
                  error?.name?.confirm ? "error" : ""
                }`}
              />
            </div>
            <div className="col-xs-12">
              <p className="password-info form-text">
                {`Use 8 or more characters with a mix of letters, numbers &
                  symbols`}
              </p>
            </div>
          </div>

          {/* Password */}
          <div className="row password-utils mb-3 mt-3">
            <div className="col-xs-6 show">
              <ShowPassword />
            </div>
          </div>

          {/* Submit */}
          <div className="row">
            <div className="col">
              <button
                className="button auth submit"
                type="submit"
                disabled={submitting}
              >
                Sign up to UStella
              </button>
            </div>
          </div>
        </form>
      </div>

      {/* Login instead */}
      <div className="row anchor login mt-4">
        <div className="col">
          <h6>Already have an account?</h6>
          <Link
            to={{ pathname: ROUTES.AUTH.LOGIN, state: { email: data.email } }}
          >
            Login to your account
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Signup;
