import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";

import ProfilePictureFallback from "../../../assets/images/profile/profile_picture-fallback.svg";
import setGreetings from "../../shared/functions/setGreetings";
import { ROUTES } from "../../../constants";

const USER_DROPDOWN_CATEGORIES = [
  {
    name: "profile-items",
    items: [
      {
        text: "Profile",
        icon: <i className="bi-person" />,
        link: ROUTES.PROFILE,
      },
    ],
  },
  {
    name: "logout",
    items: [
      {
        text: "Log out",
        icon: (
          <i className="bi-box-arrow-right" role="img" aria-label="Log out" />
        ),
        link: ROUTES.LOGOUT,
      },
    ],
  },
];

const NavbarProfile = ({ user, toggleSignOutModal }) => {
  const [isDropdownActive, setDropdown] = useState(false);
  const dropdownActiveClass = isDropdownActive ? "dropdown-active" : "";

  const node = useRef();
  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    setDropdown(false);
  };

  useEffect(() => {
    if (isDropdownActive) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownActive]);

  // Hide navbar on route change
  const location = useLocation();
  useEffect(() => {
    setDropdown(false);
  }, [location]);

  const greetings = setGreetings();
  const username = `${user?.firstName} ${user?.lastName}`;

  return (
    <>
      <div ref={node} className={`user ${dropdownActiveClass}`}>
        <button
          onClick={() => setDropdown(!isDropdownActive)}
          className="nav-button"
        >
          <div className="info">
            <span className="greetings">{greetings}</span>
            <h5 className="name">{username}</h5>
          </div>
          <div className="profile_picture">
            <img
              src={user?.image || ProfilePictureFallback}
              alt={username}
              className="img"
            />
          </div>
        </button>

        <NavbarDropdown
          isActive={isDropdownActive}
          categories={USER_DROPDOWN_CATEGORIES}
          toggleSignOutModal={toggleSignOutModal}
        />
      </div>
    </>
  );
};

const NavbarDropdown = ({ isActive, categories, toggleSignOutModal }) => {
  const activeClass = isActive ? "show" : "hide";
  return (
    <div className={`user_dropdown ${activeClass}`}>
      {categories.map((category, index) => {
        return (
          <ul className={`list ${category.name}`} key={index}>
            {category.items.map((item, index) => {
              // Logout
              if (item.link === ROUTES.LOGOUT) {
                return (
                  <li className="item log-out" key={index}>
                    <button className="link" onClick={toggleSignOutModal}>
                      <div className="icon">{item.icon}</div>
                      <span className="text">Log out</span>
                    </button>
                  </li>
                );
              }

              // Links
              return (
                <li className="item" key={index}>
                  <Link to={item.link} className="link">
                    <div className="icon">{item.icon}</div>
                    <div className="text">{item.text}</div>
                  </Link>
                </li>
              );
            })}
          </ul>
        );
      })}
    </div>
  );
};

export default NavbarProfile;
