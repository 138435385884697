import React, { useEffect, useState, createContext } from "react";
import firebase from "firebase/app";

export const FirebaseContext = createContext();

export const FirebaseProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setCurrentUser(user);
      setPending(false);
    });
  }, []);

  if (pending) return null;

  return (
    <FirebaseContext.Provider
      value={{
        currentUser,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};
