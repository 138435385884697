import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import { ROUTES } from "../../../constants";
import firebase from "firebase/app";

const NotFound = () => {
  const history = useHistory();
  const [pending, setPending] = useState(true);

  useEffect(() => {
    const user = firebase.auth().currentUser;

    if (!user) {
      history.push(ROUTES.AUTH.LOGIN);
    }

    if (user && history.location.pathname === "/") {
      history.push(ROUTES.DASHBOARD);
    }

    setPending(false);
  }, [history]);

  if (pending) return <></>;

  return (
    <div className="container not-found">
      <Helmet>
        <title>404 - UStella</title>
      </Helmet>

      <div className="row not-found__card">
        <div className="col-12 header">
          <h1 className="code">404</h1>
          <h4 className="title">Oops! Page not found.</h4>
        </div>
        <div className="col-12 go-back">
          <p className="description">
            The page you are looking for might have been removed, had it's name
            changed or is temporarily unavailable.
          </p>
          <Link to={ROUTES.DASHBOARD}>
            <button className="button route">Go back to dashboard</button>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default NotFound;
