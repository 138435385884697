const setGreetings = () => {
  const hours = new Date().getHours();

  if (hours <= 5 || hours >= 18) {
    return `Good evening,`;
  }

  if (hours > 5 && hours <= 12) {
    return `Good morning,`;
  }

  return `Have a nice day,`;
};

export default setGreetings;
