import React from "react";
import UStellaLogo from "./../../../assets/images/logo/logo.svg";

const AppLoader = () => {
  return (
    <div className="app-loader">
      <img src={UStellaLogo} alt="UStella" className="logo" />
    </div>
  );
};

export default AppLoader;
