import React, { useState } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import firebase from "firebase/app";

import { ROUTES } from "../../../constants";
import UStellaLogo from "../../../assets/images/logo/logo.svg";

import Loading from "./shared/Loading";
import ErrorBadge from "./shared/ErrorBadge";

const ForgotPassword = () => {
  const location = useLocation();
  const history = useHistory();

  const [data, setData] = useState({
    email: location?.state?.email || "",
  });

  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (submitting) return;
    setSubmitting(true);
    setError(null);

    // Check if data is filled
    if (!data.email) {
      setError(`Please enter your email.`);
      return setSubmitting(false);
    }

    await firebase
      .auth()
      .sendPasswordResetEmail(data.email)
      .then(() => {
        return history.push(ROUTES.AUTH.LOGIN, {
          email: data.email,
          resetPassword: true,
        });
      })
      .catch(() => setError(`Something went wrong. Please try again later.`));

    return setSubmitting(false);
  };

  return (
    <div className={`container auth ${submitting ? "submitting" : ""}`}>
      <div className="row title">
        <div className="col">
          <img src={UStellaLogo} alt="UStella" className="logo" />
          <h4 className="header">Reset your password</h4>
        </div>
      </div>

      <div className="divider mt-2" />

      <div className="row loader pb-3">
        <div className="col">
          <Loading isLoading={submitting} />
        </div>
      </div>

      <div className="row error">
        <div className="col">
          <ErrorBadge error={error} />
        </div>
      </div>

      <div className="row info">
        <div className="col">
          <p>
            Enter the email address associated with your account, and we’ll
            email you a link to reset your password.
          </p>
        </div>
      </div>

      <div className="row form py-2">
        {/* Form */}
        <form onSubmit={onSubmit} className="col form-col">
          {/* Email  */}
          <div className="row input_row">
            <div className="col input_group">
              <label htmlFor="email">Email address</label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Email address"
                onChange={(e) =>
                  setData({ email: e.target.value, password: data.password })
                }
                value={data.email}
              />
            </div>
          </div>

          <div className="row submit_row mt-4">
            <div className="col submit">
              <button
                className="button auth submit"
                type="submit"
                disabled={submitting}
              >
                Send reset link
              </button>
            </div>
          </div>
        </form>
      </div>

      <div className="divider mt-4 mb-3" />

      {/* Go back */}
      <div className="row anchor login">
        <div className="col">
          <h6>Didn't want to reset it?</h6>
          <Link
            to={{ pathname: ROUTES.AUTH.LOGIN, state: { email: data.email } }}
          >
            Login to your account
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
