import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";

import { ROUTES } from "../../../constants";
import { QUERIES } from "../../../lib/apollo/queries";

import Loading from "./shared/Loading";
import ErrorBadge from "./shared/ErrorBadge";

import signOut from "../../shared/functions/auth/signOut";

const Profile = ({ user, refetchUser }) => {
  const userData = user.data.result;
  const history = useHistory();

  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const [updateProfile] = useMutation(QUERIES.uStellaSetLandlordProfile);

  const { handleSubmit, register } = useForm({
    defaultValues: {
      firstName: userData.firstName,
      lastName: userData.lastName,
      phone: userData.phone,
      address: userData.address,
    },
  });
  const onSubmit = async (data) => {
    setSubmitting(true);
    setError(null);

    const payload = {
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      address: data.address,
    };

    // return;
    await updateProfile({
      variables: {
        payload,
      },
    })
      .then(async () => {
        return await refetchUser().then((res) => res);
      })
      .then(async (res) => {
        const jwtToken = res.data.result.jwtToken;

        if (jwtToken) {
          refetchUser();
        } else {
          await signOut();
          setSubmitting(false);

          history.push(ROUTES.AUTH.LOGIN);
          return;
        }
      })
      .catch((err) => {
        console.log(err);
        setError(
          `Something went wrong. Please refresh the page and try again.`
        );
        setSubmitting(false);
      });
  };

  return (
    <div className={`container auth ${submitting ? "submitting" : ""}`}>
      <div className="row title">
        <div className="col">
          <h4 className="header">Update your profile</h4>
        </div>
      </div>

      <div className="divider mt-4" />

      <div className="row loader pb-3">
        <div className="col">
          <Loading isLoading={submitting} />
        </div>
      </div>

      {error && (
        <div className="row error">
          <div className="col">
            <ErrorBadge error={error} />
          </div>
        </div>
      )}

      <div className="row form">
        <form onSubmit={handleSubmit(onSubmit)} className="col form-col">
          {/* First name  */}
          <div className="row input_row">
            <div className="col input_group">
              <label htmlFor="firstName">First name</label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                autoComplete="off"
                ref={register({ required: true })}
              />
            </div>
          </div>

          {/* Last name  */}
          <div className="row input_row">
            <div className="col input_group">
              <label htmlFor="lastName">Last name</label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                autoComplete="off"
                ref={register({ required: true })}
              />
            </div>
          </div>

          {/* Phone number  */}
          <div className="row input_row">
            <div className="col input_group">
              <label htmlFor="phone">Phone number</label>
              <input
                type="text"
                name="phone"
                id="phone"
                autoComplete="off"
                ref={register({ required: true })}
              />
            </div>
          </div>

          {/* Address  */}
          <div className="row input_row">
            <div className="col input_group">
              <label htmlFor="address">Address</label>
              <input
                type="text"
                name="address"
                id="address"
                autoComplete="off"
                ref={register({ required: true })}
              />
            </div>
          </div>

          {/* Submit */}
          <div className="row mt-4">
            <div className="col">
              <button
                className="button auth submit"
                type="submit"
                disabled={submitting}
              >
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Profile;
