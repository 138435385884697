import firebase from "firebase/app";

const getFirebaseToken = (data) => {
  return new Promise(async (resolve, reject) => {
    await firebase
      .auth()
      .signInWithEmailAndPassword(data.email, data.password)
      .then((res) => res.user.getIdToken())
      .then((token) => resolve(token))
      .catch((err) => {
        const code = err.code;
        let message = null;

        if (code === `auth/too-many-requests`) {
          message = `Too many requests. Please wait and try again later.`;
        } else {
          message = `The email and password you entered did not match our records. Please double-check and try again.`;
        }

        reject(message);
      });
  });
};

export default getFirebaseToken;
