import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import { ROUTES } from "../../../constants";
import LogoWide from "../../../assets/images/logo/logo-wide.svg";
import { useBreadcrumb } from "../../../contexts/BreadcrumbContext";
import SignOutModal from "../../modals/SignOut";
import NavbarProfile from "./Profile";

const BreadcrumbArea = () => {
  const location = useLocation();

  const breadcrumbs = useBreadcrumb();

  // Set previous pathname
  const [pathnames, setPathnames] = useState({
    prev: null,
    current: null,
  });

  useEffect(() => {
    if (pathnames.current !== location.pathname) {
      setPathnames({
        prev: pathnames.current,
        current: location.pathname,
      });
    }
  }, [pathnames, location]);

  if (!breadcrumbs) return <></>;
  const length = breadcrumbs.length;

  return breadcrumbs.map((breadcrumb, index) => {
    // Detect if the breadcrumb item's pathname is the last but one
    // const []

    return (
      <div className="breadcrumb-item" key={index}>
        {breadcrumb.subtitle && (
          <span className="breadcrumb-subtitle">{breadcrumb.subtitle}</span>
        )}

        {length !== index + 1 && breadcrumb.pathname ? (
          <Link
            to={breadcrumb.pathnameWithQuery || breadcrumb.pathname || "/"}
            className="breadcrumb-text breadcrumb-link"
          >
            {breadcrumb.title}
          </Link>
        ) : length === index + 1 ? (
          <span className="breadcrumb-text breadcrumb-current">
            {breadcrumb.title}
          </span>
        ) : (
          <span className="breadcrumb-text breadcrumb-no-link">
            {breadcrumb.title}
          </span>
        )}
      </div>
    );
  });
};

const Navbar = ({ user }) => {
  const breadcrumbs = useBreadcrumb();

  const [prompt, setPrompt] = useState(false);
  const toggleSignOutModal = () => {
    setPrompt(!prompt);
  };
  return (
    <>
      <SignOutModal prompt={prompt} toggleSignOutModal={toggleSignOutModal} />
      <nav className="navbar">
        <div className="container container-navbar breadcrumb-container breadcrumb-desktop">
          <div className="breadcrumb-list">{<BreadcrumbArea />}</div>

          <div className="ustella-branding">
            <Link to={ROUTES.DASHBOARD}>
              <img src={LogoWide} alt="UStella" className="logo" />
            </Link>
          </div>

          <div className="user_area">
            <NavbarProfile
              user={user}
              toggleSignOutModal={toggleSignOutModal}
            />
          </div>
        </div>
      </nav>
      {breadcrumbs && (
        <div className="container breadcrumb-container breadcrumb-mobile">
          <div className="breadcrumb-list">{<BreadcrumbArea />}</div>
        </div>
      )}
    </>
  );
};

export default Navbar;
