import { ApolloClient, InMemoryCache } from "@apollo/client";
import { ApolloLink } from "apollo-link";
import omitDeep from "omit-deep";
import { createUploadLink } from "apollo-upload-client";

const linkOptions = {
  uri: process.env.REACT_APP_TEST_GRAPHQL_ENDPOINT_URI.replace(
    "%host",
    window.location.hostname
  ),
  fetch: (uri, options) => {
    options.headers["x-token"] = localStorage.getItem("token");
    return fetch(uri, options);
  },
};

const apolloMiddleware = new ApolloLink((operation, forward) => {
  if (operation.variables && !operation.variables.file) {
    operation.variables = omitDeep(operation.variables, "__typename");
  }
  return forward(operation);
});

export const client = new ApolloClient({
  uri: process.env.REACT_APP_TEST_GRAPHQL_ENDPOINT_URI.replace(
    "%host",
    window.location.hostname
  ),
  cache: new InMemoryCache(),
  link: apolloMiddleware.concat(createUploadLink(linkOptions)),
});

export default client;
