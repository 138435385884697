import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/analytics";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

// class Firebase {
//   constructor() {
//     app.initializeApp(config);
//     this.auth = app.auth();
//     this.storage = app.storage();
//     this.analytics = app.analytics();
//   }

//   signIn(email, password) {
//     return this.auth.signInWithEmailAndPassword(email, password);
//   }

//   resetPassword(email) {
//     return this.auth.sendPasswordResetEmail(email);
//   }

//   confirmPasswordReset(code, newPassword) {
//     return this.auth.confirmPasswordReset(code, newPassword);
//   }

//   signOut() {
//     return this.auth.signOut();
//   }

//   signUp(email, password) {
//     this.auth
//       .createUserWithEmailAndPassword(email, password)
//       .then(function (data) {
//         return data.user.uid;
//       })
//       .catch(function (error) {
//         //Handle error
//       });
//   }

//   isInitialized(resolve) {
//     return this.auth.onAuthStateChanged(resolve);
//   }

//   getCurrentUsername() {
//     return this.auth.currentUser && this.auth.currentUser.displayName;
//   }
// }

// export default new Firebase();
