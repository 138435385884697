import { useEffect } from "react";
import firebase from "firebase/app";

import signOut from "../shared/functions/auth/signOut";

const SignOutModal = ({ prompt, toggleSignOutModal }) => {
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        toggleSignOutModal();
      }
    });
  });

  return (
    <div className={`sign_out-modal ${prompt ? "show" : ""}`}>
      <div className="content_holder">
        <div className="modal_content">
          <h2 className="title">Log out of UStella?</h2>
          <p className="description">You can always log back in at any time.</p>
          <div className="buttons">
            <button onClick={toggleSignOutModal} className="btn cancel">
              Cancel
            </button>
            <button onClick={signOut} className="btn log-out">
              Log out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignOutModal;
