const Check = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="currentColor"
      className="bi bi-check2"
      viewBox="0 0 16 16"
    >
      <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
    </svg>
  );
};

const Checkbox = ({ name, label, onClick, value }) => {
  return (
    <div className="checkbox__container">
      <label className="checkbox" htmlFor={name}>
        <span className="label">{label}</span>
        <input
          type="checkbox"
          id={name}
          name={name}
          value={value}
          className="checkbox"
          onClick={onClick}
        />

        <span className="checkmark">{value === true && <Check />}</span>
      </label>
    </div>
  );
};

export default Checkbox;
