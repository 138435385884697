import React, { useEffect, Fragment, useState } from "react";

import { useLocation, useHistory, withRouter } from "react-router-dom";

const ScrollToTop = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const [currentPathname, setPathname] = useState(null);

  useEffect(() => {
    if (currentPathname) return;
    setPathname(location.pathname);
  }, [location, currentPathname]);

  useEffect(() => {
    // Disable scrolling on going back and forth
    if (history.action === "POP") return;

    const pathname = location.pathname;
    if (pathname !== currentPathname) {
      setPathname(pathname);
      window.scrollTo(0, 0);
      return;
    }
  }, [location, currentPathname, history]);

  return <Fragment>{children}</Fragment>;
};

export default withRouter(ScrollToTop);
