import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const BreadcrumbContext = React.createContext();
export const BreadcrumbUpdateContext = React.createContext();

export function useBreadcrumb() {
  return useContext(BreadcrumbContext);
}
export function useBreadcrumbUpdate() {
  return useContext(BreadcrumbUpdateContext);
}

export function BreadcrumbProvider({ children }) {
  const location = useLocation();

  const [breadcrumb, setBreadcrumb] = useState(null);
  const changeBreadcrumb = (values) => {
    setBreadcrumb(values);
  };

  // Auto remove breadcrumb if the page is not revelant anymore
  useEffect(() => {
    if (!breadcrumb) return;
    if (!breadcrumb[0].pathname) return;

    const paths = location.pathname.split("/");
    paths.shift();
    const firstPath = paths[0];

    const breadcrumbPaths = breadcrumb[0].pathname.split("/");
    breadcrumbPaths.shift();
    const firstBreadcrumbPath = breadcrumbPaths[0];

    if (firstPath !== firstBreadcrumbPath) {
      setBreadcrumb(null);
    }
  }, [location, breadcrumb]);

  return (
    <BreadcrumbContext.Provider value={breadcrumb}>
      <BreadcrumbUpdateContext.Provider value={changeBreadcrumb}>
        {children}
      </BreadcrumbUpdateContext.Provider>
    </BreadcrumbContext.Provider>
  );
}
