import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

import { ROUTES } from "../../constants";
import ProfilePictureFallback from "../../assets/images/profile/profile_picture-fallback.svg";
import SignOutModal from "../modals/SignOut";
import setGreetings from "../shared/functions/setGreetings";
import Logo from "../../assets/images/logo/logo.svg";

const SIDEBAR_ITEMS = [
  {
    name: "route-items",
    items: [
      {
        text: "Dashboard",
        icon: <i className="bi-house" role="img" aria-label="Dashboard" />,
        link: ROUTES.DASHBOARD,
      },
      {
        text: "Maintenance",
        icon: <i className="bi-wrench" role="img" aria-label="Maintenance" />,
        link: ROUTES.MAINTENANCE,
      },
    ],
  },
  {
    name: "profile-items",
    items: [
      {
        text: "Profile",
        icon: <i className="bi-person" role="img" aria-label="Profile" />,
        link: ROUTES.PROFILE,
      },
      {
        text: "Log out",
        icon: (
          <i className="bi-box-arrow-right" role="img" aria-label="Log out" />
        ),
        link: ROUTES.LOGOUT,
      },
    ],
  },
];

const Sidebar = ({ user }) => {
  const [prompt, setPrompt] = useState(false);
  const toggleSignOutModal = () => {
    setPrompt(!prompt);
  };

  const [sidebarActive, setSidebar] = useState(false);
  const toggleSidebar = () => {
    setSidebar(!sidebarActive);
  };

  // Hide sidebar on route change
  const location = useLocation();
  useEffect(() => {
    setSidebar(false);
  }, [location]);

  const greetings = setGreetings();
  const username = `${user?.firstName} ${user?.lastName}`;

  return (
    <>
      <SignOutModal prompt={prompt} toggleSignOutModal={toggleSignOutModal} />
      <div className={`sidebar ${sidebarActive ? "show" : ""}`}>
        <div className={`sidebar__toggler ${sidebarActive ? "active" : ""}`}>
          <button onClick={toggleSidebar} className="toggler_button">
            {sidebarActive ? <i className="bi-x" /> : <i className="bi-list" />}
          </button>
        </div>

        <div className="content-holder">
          <div className="content">
            {/* Branding */}

            <div className="ustella-branding">
              <Link to={ROUTES.DASHBOARD}>
                <img src={Logo} alt="UStella" className="logo" />
              </Link>
            </div>

            {/* User */}
            <div className="user">
              <div className="username">
                <span className="greetings">{greetings}</span>
                <h4 className="name">{username}</h4>
              </div>

              <img
                src={user?.profile_image || ProfilePictureFallback}
                alt={username}
                className="profile_image"
              />
            </div>

            {SIDEBAR_ITEMS.map((category, index) => {
              return (
                <ul className={`list-category ${category.name}`} key={index}>
                  {category.items.map((item, index) => {
                    // Logout
                    if (item.link === ROUTES.LOGOUT) {
                      return (
                        <li className="item log-out" key={index}>
                          <button className="link" onClick={toggleSignOutModal}>
                            <div className="icon">{item.icon}</div>
                            <span className="text">Log out</span>
                          </button>
                        </li>
                      );
                    }

                    // Navlink
                    return (
                      <li className="item" key={index}>
                        <NavLink
                          to={item.link}
                          activeClassName="active"
                          className="link"
                        >
                          <div className="icon">{item.icon}</div>
                          <span className="text">{item.text}</span>
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
